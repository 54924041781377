import { render, staticRenderFns } from "./RebateRecordTable.vue?vue&type=template&id=34f4801c&scoped=true"
import script from "./RebateRecordTable.vue?vue&type=script&setup=true&lang=js"
export * from "./RebateRecordTable.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34f4801c",
  null
  
)

export default component.exports