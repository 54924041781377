<template>
  <div class="year-statistic">
    <StatisticBlock :data="displayDataA" />
    <StatisticBlock :data="displayDataB" />
  </div>
</template>

<script setup>
import StatisticBlock from '@/components/Report/StatisticBlock.vue'
import { GetOriginTotalAmount } from '@/api/rebate'
import { useRangeTime } from '@/use/rangeTime'
import store from '@/store'
import { computed, watch, ref, onMounted } from 'vue'
import { get } from 'lodash'

const { getTodayRange } = useRangeTime()
const props = defineProps({
  dateRange: {
    type: Array,
    default: () => [],
  },
})

watch(props, async () => {
  await getOriginTotalAmount()
})

const data = ref(null)
const shopId = computed(() => store.getters.shop)

const totalAdd = computed(() => {
  if (!data.value) return '-'
  return get(data.value, 'ADMIN_ADD', 0) +
    get(data.value, 'EC_ORDER_ADD', 0) +
    get(data.value, 'LOTTERY_AWARD_ADD', 0) +
    get(data.value, 'POINT_EXCHANGE_ADD', 0) +
    get(data.value, 'REWARD_ACTIVITY_EC_ORDER_ADD', 0) +
    get(data.value, 'REWARD_ACTIVITY_MEMBER_EXTERNAL_TRANSACTION_ADD', 0) +
    get(data.value, 'REWARD_ACTIVITY_MEMBER_STORE_ADD', 0) +
    get(data.value, 'REWARD_ACTIVITY_SALE_RECORD_ADD', 0) +
    get(data.value, 'SALE_RECORD_ADD', 0) +
    get(data.value, 'SERIAL_ACTIVITY_ADD', 0) +
    get(data.value, 'EVENT_PLAYBOOK_SEND', 0)
})
const totalUse = computed(() => {
  if (!data.value) return '-'
  return get(data.value, 'EC_ORDER_USE', 0) +
    get(data.value, 'SALE_RECORD_USE', 0) +
    get(data.value, 'MEMBER_STORE_USE', 0)
})
const totalCancel = computed(() => {
  if (!data.value) return '-'
  return get(data.value, 'ADMIN_USE', 0) +
    get(data.value, 'EC_ORDER_ADD_CANCEL', 0) +
    get(data.value, 'SALE_RECORD_ADD_CANCEL', 0) +
    get(data.value, 'POINT_EXCHANGE_ADD_CANCEL', 0) +
    get(data.value, 'REWARD_ACTIVITY_EC_ORDER_ADD_CANCEL', 0) +
    get(data.value, 'REWARD_ACTIVITY_MEMBER_EXTERNAL_TRANSACTION_ADD_CANCEL', 0) +
    get(data.value, 'REWARD_ACTIVITY_MEMBER_STORE_ADD_CANCEL', 0) +
    get(data.value, 'REWARD_ACTIVITY_SALE_RECORD_ADD_CANCEL', 0)
})
const total = computed(() => {
  if (!data.value) return '-'
  const count = totalAdd.value +
    totalUse.value +
    totalCancel.value +
    get(data.value, 'EXPIRED')
  return count
})

const totalNoCancel = computed(() => {
  if (!data.value) return '-'
  const count = totalAdd.value +
    totalUse.value +
    totalCancel.value
  return count
})

const displayDataA = computed(() => [
  { label: '實際回饋金額', value: totalAdd.value },
  { label: '實際折抵金額', value: totalUse.value },
  { label: '回收金額', value: totalCancel.value },
])

const displayDataB = computed(() => [
  { label: '過期金額', value: get(data.value, 'EXPIRED', '-') },
  { label: '總進出差額', value: total.value, tooltip: '總進出差額 = 實際回饋金額 - 實際折抵金額 - 回收金額 - 過期金額' },
  { label: '總進出差額(排除過期)', value: totalNoCancel.value, tooltip: '總進出差額(排除過期) = 實際回饋金額 - 實際折抵金額 - 回收金額' },
])

const getOriginTotalAmount = async () => {
  let dates = props.dateRange
  if (!dates.length) dates = getTodayRange()
  const [res, err] = await GetOriginTotalAmount({
    shopId: shopId.value,
    createStart: dates[0],
    createEnd: dates[1],
  })
  if (err) {
    window.$message.error(err)
    return
  }
  data.value = res
}

onMounted(async () => {
  await getOriginTotalAmount()
})
</script>

<style scoped lang="postcss">

</style>
