<template>
  <main class="">
    <PageTitle title="回饋金報表" btn="匯出" @btnClick="dialog.exportOptions = true" />
    <YearStatistic />
    <RebateRecordTable :dialog.sync="dialog" />
  </main>
</template>

<script>
import RebateRecordTable from './components/RebateRecordTable.vue'
import YearStatistic from './components/YearStatistic.vue'
import { reactive, defineComponent } from 'vue'

export default defineComponent({
  name: 'RebateReport',
  components: { RebateRecordTable, YearStatistic },
  setup () {
    const dialog = reactive({
      exportOptions: false,
      exporting: false,
    })

    return {
      dialog,
    }
  },
})

</script>

<style scoped lang="postcss">

</style>
